<template>
  <div class="mt-12">
    <div class="card bg-white rounded-xl p-4 pb-9 mb-12">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          Expense ({{totalCount}})
        </div>
      </div>
      <div class="mt-8">
        <div class="flex overflow-auto mb-2 items-center justify-between">
          <div class="flex items-center">
            <div class="pr-2 text-gray4 heading-5">Added By:</div>
            <div class="" v-for="(status, index) in empStatusList" :key="index" :class="`${index === 0 ? 'left-0 sticky bg-white' : ''}`">
              <StatusBtn v-if="activeUer !== status.userDetailId" class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveB(index)" :selected="status.selected" :btnText="status.fullName" :lastInlist="empStatusList.length === index + 1"/>     
              <StatusBtn v-else class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveB(index)" :selected="status.selected" :btnText="'Me'" :lastInlist="empStatusList.length === index + 1"/>     
            </div>
          </div>
        </div>
        <div class="flex overflow-auto mb-2 items-center justify-between">
          <div class="flex items-center">
            <div class="pr-2  text-gray4 heading-5 ">Status:</div>
            <div class="" v-for="(status, index) in entStatusList" :key="index"
            :class="`${index === 0 ? 'left-0 sticky bg-white' : ''}`">
              <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveA(index)" :selected="status.selected" :btnText="status.statusName" :lastInlist="entStatusList.length === index + 1"/>     
            </div>
          </div>
          <div>
            <span @click="filterShow = true" :class="`cursor-pointer text-3xl ${filterApply ? 'text-success' : 'text-primary'}`"><i class="fa-solid fa-filter"></i></span>
          </div>
        </div>
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Expense...`"/>
          </div>
          <div class="mb-4">
            <Button @buttonAction="addNewCust(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Expense'"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div v-if="!mobileView">
            <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
              <div class="name_col uppercase w-14  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''">
                <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}}</span>
                 <div v-if="titleObj[0].icon !== ''">
                    <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                    <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                  </div>
              </div>
              <div class="secondory_col uppercase w-96 flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''">
                <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
                 <div v-if="titleObj[1].icon !== ''">
                    <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                    <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                  </div>
              </div>
              <div class="name_col uppercase w-56 flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''">
                <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
                 <div v-if="titleObj[2].icon !== ''">
                    <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                    <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                  </div>
              </div>
              <div class="name_col uppercase w-56 flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''">
                <span class="cursor-pointer" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
                 <div v-if="titleObj[3].icon !== ''">
                    <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                    <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                  </div>
              </div>
              <div class="name_col uppercase w-56 flex items-center" :class="titleObj[4].icon !== '' ? 'text-primary' : ''">
                <span class="cursor-pointer" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
                 <div v-if="titleObj[4].icon !== ''">
                    <descIconSvg v-if="titleObj[4].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                    <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                  </div>
              </div>
              <div class="name_col uppercase w-56 flex items-center" :class="titleObj[5].icon !== '' ? 'text-primary' : ''">
                <span class="cursor-pointer" @click="sortByColumn(5)">{{titleObj[5].title}}</span>
                 <div v-if="titleObj[5].icon !== ''">
                    <descIconSvg v-if="titleObj[5].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                    <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                  </div>
              </div>
              <div class="name_col uppercase w-56 flex items-center" :class="titleObj[6].icon !== '' ? 'text-primary' : ''">
                <span class="cursor-pointer" @click="sortByColumn(6)">{{titleObj[6].title}}</span>
                 <div v-if="titleObj[6].icon !== ''">
                    <descIconSvg v-if="titleObj[6].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                    <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                  </div>
              </div>
              <div class="name_col uppercase w-56 flex items-center" :class="titleObj[7].icon !== '' ? 'text-primary' : ''">
                <span class="cursor-pointer" @click="sortByColumn(7)">{{titleObj[7].title}}</span>
                 <div v-if="titleObj[7].icon !== ''">
                    <descIconSvg v-if="titleObj[7].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                    <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                  </div>
              </div>
            </div>
            <div v-if="expenseList.length > 0">
              <div v-for="(data, index) in expenseList" :key="index" class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openDetails(data)">
                <div class="name_col w-14">
                  <div class="flex items-start">
                  <span class="text pr-2 text-success" v-if="data.isMarkPaid"><i class="fas fa-circle"></i></span>
                  <p class="text pr-2 text-error" v-if="!data.isMarkPaid"><i class="fas fa-circle"></i></p>
                  <span class="text-text2 heading-6 font-semibold">{{data.itemName}}</span>
                  </div>
                </div>
                <div class="secondory_col w-96">
                  <div class="grid" v-if="data.jobId > 0">
                    <span class="text-gray4 heading-6"># {{data.jobNumber}}</span>
                    <span class="text-gray4 heading-6">{{data.jobTitle === '' ? '-' : data.jobTitle}}</span>
                  </div>
                  <div class="" v-else>
                    <span class="text-text2 heading-6">-</span>
                  </div>
                </div>
                <div class="name_col w-56">
                  <div class="flex items-center">
                  <span class="text-gray4 heading-6" v-if="data.accountingCodeName !== ''">{{data.accountingCodeName}}</span>
                  <span class="text-gray4 heading-6" v-else>-</span>
                  </div>
                </div>
                <div class="name_col w-56">
                  <div class="flex items-center">
                  <span class="text-gray4 heading-6">{{data.totalAmount | amountFormaterWithToFix}}</span>
                  </div>
                </div>
                  <div class="name_col w-56">
                    <div class="flex items-center">
                    <span class="text-gray4 heading-6" v-if="data.reimbersUserName !== ''">{{data.reimbersUserName}}</span>
                    <span class="text-gray4 heading-6" v-else>-</span>
                    </div>
                  </div>
                  <div class="name_col w-56">
                    <div class="flex items-center">
                    <span class="text-gray4 heading-6">{{data.firstName}} {{data.lastName}}</span>
                    </div>
                  </div>
                <div class="name_col w-56">
                  <div class="flex items-center">
                  <span class="text-gray4 heading-6" v-if="data.addedDate !== ''">{{data.addedDate | formatDataeTime}}</span>
                  <span class="text-gray4 heading-6" v-else>-</span>
                  </div>
                </div>
                <div class="name_col w-56">
                  <div class="flex items-center">
                  <span class="text-gray4 heading-6" v-if="data.expenseDate !== ''">{{data.expenseDate | formatDataeTime}}</span>
                  <span class="text-gray4 heading-6" v-else>-</span>
                  </div>
                </div>
              </div>
              <span class="text-primary heading-6 text-right name_col py-2 fixed" style="position: absolute;left: 690px;" v-if="totalAmount !== 0">Total: {{totalAmount | amountFormaterWithToFix}}</span>
            </div>
          </div>
          <div v-if="mobileView">
            <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="expenseList.length > 0">
              <div class="flex items-center border border-primary p-2 m-2 rounded-md">
                <p class="w-20 text-primary heading-5">Total:</p>
                <p class="text-primary heading-5">{{totalAmount | amountFormaterWithToFix}}</p>
              </div>
              <div v-for="(data, index) in expenseList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openDetail(data.codeId, data)">
                <div class="items-center">
                  <div class="text-text2 heading-5 font-semibold overFlowParaA items-center" >
                    <span class="text text-success" v-if="data.isMarkPaid"><i class="fas fa-circle"></i></span>
                    <span class="text text-error" v-if="!data.isMarkPaid"><i class="fas fa-circle"></i></span>
                    {{data.itemName}}</div>
                  <p class="text-gray4 heading-6" v-if="data.jobNumber > 0">#{{data.jobNumber}}</p>
                  <p class="text-gray4 heading-6" v-if="data.jobTitle !== ''">{{data.jobTitle}}</p>
                  <div class="py-1">
                    <div class="flex items-center ">
                      <p class="w-40 text-gray3 heading-5">Reimburse To:</p>
                      <p class="text-gray4 heading-5">{{data.reimbersUserName}}</p>
                    </div>
                    <div class="flex items-center ">
                      <p class="w-40 text-gray3 heading-5">Added By:</p>
                      <p class="text-gray4 heading-5">{{data.firstName}} {{ data.lastName }}</p>
                    </div>
                  </div>
                  <div class="py-1">
                    <div class="flex items-center ">
                      <p class="w-40 text-gray3 heading-5">Accounting Code:</p>
                      <p class="text-gray4 heading-5" v-if="data.accountingCodeName !== ''">{{data.accountingCodeName}}</p>
                      <p class="text-gray4 heading-5" v-else>-</p>
                    </div>
                    <div class="flex items-center">
                      <p class="w-40 text-gray3 heading-5">Total Amount:</p>
                      <p class="text-gray4 heading-5">{{data.totalAmount | amountFormaterWithToFix}}</p>
                    </div>
                  </div>
                  <div class="py-1">
                    <div class="flex items-center ">
                      <p class="w-40 text-gray3 heading-5">Added Date:</p>
                      <p class="text-gray4 heading-5" v-if="data.addedDate !== ''">{{data.addedDate | formatDataeTime}}</p>
                      <p class="text-gray4 heading-5" v-else>-</p>
                    </div>
                    <div class="flex items-center">
                      <p class="w-40 text-gray3 heading-5">Expense Date:</p>
                      <p class="text-gray4 heading-5" v-if="data.expenseDate !== ''">{{data.expenseDate | formatDataeTime}}</p>
                      <p class="text-gray4 heading-5" v-else>-</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
          <div v-if="expenseList.length === 0">
            <NoRecords :alertMessage="`No records found.`" />
          </div>
        </div>
          <div>
            <Pagination :totalDataCount="totalCount" :resetPagination="resetPaginationValues"
              @callApiAsPerPagination="getDataFromPagination" />
          </div>
      </div>
    </div>
      <FilterDrawer @applyFilters="applyFilters" :navigationDialog="filterShow"/>
   </div>
</template>
<script>
import FilterDrawer from '@/View/invoice/components/InvoiceFilterDrawer.vue';
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import Pagination from '@/View/components/pagination.vue'
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    FilterDrawer,
    ascIconSvg,
    descIconSvg,
    StatusBtn,
    SearchTextInput,
    NoRecords,
    Button,
    Pagination,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      filterShow: false,
      filterApply: false,
      totalAmount: 0,
      mobileView: false,
      activeUer: 0,
      entStatusList: [
        { statusId: 1, statusName: 'Paid', selected: true, value: 'paid'},
        { statusId: 2, statusName: 'Unpaid', selected: true, value: 'unpaid'},
      ],
      empStatusList: [],
      titleObj: [
        {title: 'Title', icon: '', sortName: 'itemName'},
        {title: 'Job Title', icon: '', sortName: 'jobTitle'},
        {title: 'Accounting Code', icon: '', sortName: 'accountingCodeName'},
        {title: 'Total Amount', icon: '', sortName: 'totalAmount'},
        {title: 'Reimburse to', icon: '', sortName: 'reimbersUserName'},
        {title: 'Added By', icon: '', sortName: 'firstName'},
        {title: 'Added Date', icon: 'DESC', sortName: 'addedDate'},
        {title: 'Expense Date', icon: '', sortName: 'expenseDate'}
      ],
      expenseList: [],
      searchForExp: '',
      getLeadListDebounce: null,
      resetPaginationValues: false,
      addTax: false,
      totalCount: 0,
      selectedCatId: 0,
      selectedDetail: {},
      filterObject: {
        to: '',
        from: '',
        markStatus: [],
        start: 0,
        length: 20,
        entStatusList: [],
        empStatusList: [],
        sortOrder: 'DESC',
        isActive: true,
        sortBy: 'expenseId',
        statusList: []
      },
    };
  },
  created() {
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.resizeWindowHandler()
    // this.getEnitityList()
    this.getEmployeeList()
    document.body.style = 'overflow: visible;'
    this.getExpenseList()
     this.getLeadListDebounce = this.debounce(function () {
      this.getExpenseList()
      this.resetPaginationValues = true
    }, 500)
  },
  beforeDestroy() {
    this.$root.$off('addCustomerHandler')
  },
  methods: {
    applyFilters (data) {
    console.log(data);
    if (data === null) {
      this.filterObject.from = ''
      this.filterObject.to = ''
      this.filterApply = false
      this.getExpenseList()
    } else if (!data) {
      this.filterShow = false
      return
    } else {
      this.filterApply = true
      this.filterObject.from = data.startDate
      this.filterObject.to = data.endDate
      this.getExpenseList()
    }
    this.filterShow = false
  },
    // getEnitityList () {
    //   MyJobApp.getEntityList(
    //     response => {
    //       let temp = response.Data
    //       for (let index = 0; index < temp.length; index++) {
    //         temp[index].selected = true
    //       }
    //       const newFirstElement = {
    //         entityId: 0,
    //         entityName: "All",
    //         entitySlug: "all",
    //         selected: true
    //       }
    //       this.entStatusList = [newFirstElement].concat(temp)
    //       this.$store.dispatch('SetLoader', {status: false, message: ''})
    //     },
    //     () => {
    //       this.resetPaginationValues = false
    //       this.$store.dispatch('SetLoader', {status: false, message: ''})
    //     }
    //   )
    // },
    openDetails (data) {
      // this.$router.push({name: 'EditExpense', params: {expId: data.expenseId}})
      this.$router.push({name: 'ExpenseDetail', params: {expId: data.expenseId}})
    },
    getEmployeeList () {
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          const findex = temp.findIndex(x => x.userDetailId === this.activeUer)
          if (findex > -1) {
            let element = temp[findex];
            temp.splice(findex, 1);
            temp = [element, ...temp];
          }
          for (let index = 0; index < temp.length; index++) {
            temp[index].selected = true
            temp[index].fullName = temp[index].firstName + temp[index].lastName.substr(0, 1)
          }
          const newFirstElement = {
            userDetailId: 0,
            fullName: "All",
            selected: true
          }
          this.empStatusList = [newFirstElement].concat(temp)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    toggleActiveAndInactiveA (index) {
      this.entStatusList[index].selected = !this.entStatusList[index].selected
      this.resetPaginationValues = true
    },
    toggleActiveAndInactiveB (index) {
      let filter = []
      if (this.empStatusList[index].fullName.toLowerCase() === 'all') {
        this.empStatusList[0].selected = true
        this.filterObject.empStatusList = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      } else {
        this.empStatusList[index].selected = !this.empStatusList[index].selected
        this.filterObject.empStatusList = []
        this.empStatusList.forEach((a, id) => {
          if (this.empStatusList[0].selected) {
            if (id !== index && id !== 0) {
              a.selected = false
            } else {
              if (id !== 0) {
                a.selected = true
                this.filterObject.empStatusList.push(a.userDetailId)
              }
            }
          } else {
            if (a.selected) {
              filter.push(a)
              this.filterObject.empStatusList.push(a.userDetailId)
            }
          }
        })
        this.empStatusList[0].selected = false
      }
      if (filter.length === this.empStatusList.length - 1) {
        this.empStatusList[0].selected = true
        this.filterObject.empStatusList = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      let arry = this.empStatusList.filter(a => {
        return a.selected === false
      })
      if (arry.length === this.empStatusList.length) {
        this.empStatusList[0].selected = true
        this.filterObject.empStatusList = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      this.resetPaginationValues = true
    },
    openDetail (Id, data) {
      this.selectedCatId = Id
      this.selectedDetail = data
    },
    addNewCust () {
      this.$router.push({name: 'AddExpense', params: {expId: 0}})
      this.selectedCatId = 0
      this.selectedDetail = {}
    },
    getDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      this.getExpenseList(true)
    },
    searchCall (data) {
      this.searchForExp = data
      this.getLeadListDebounce()
    },
    resizeWindowHandler() {
      if (window.innerWidth < 800) {
        this.mobileView = true
      } else {
        this.mobileView = false
      }
    },
    sortByColumn (index) {
      console.log(index);
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getExpenseList(true)
    },
    getExpenseList (showlistLoader) {
      if (showlistLoader && this.searchForExp !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchForExp === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      this.filterObject.markStatus = []
      this.entStatusList.map((data) => {
      if (data.selected) {
        this.filterObject.markStatus.push(data.value)
        }
      })
      MyJobApp.getExpenseList(
          this.filterObject.start,
          this.filterObject.length,
          this.searchForExp,
          this.filterObject.sortBy,
          this.filterObject.sortOrder,
          this.filterObject.empStatusList,
          this.filterObject.markStatus,
          this.filterObject.from,
          this.filterObject.to,
          response => {
            this.totalAmount = 0
            this.expenseList = response.Data.tableRow !== null ? response.Data.tableRow : []
            for (let index = 0; index < this.expenseList.length; index++) {
              this.totalAmount += this.expenseList[index].totalAmount
            }
            this.totalCount = response.Data.count
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    }
  },
};
</script>
<style scoped>
.name_col {
  min-width: 203px !important;
  width: 203px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.secondory_col {
  min-width: 296px !important;
  width: 296px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>